import { useEffect, useState } from "react"

export default function Slideshow() {
  const [currentImg, setCurrentImg] = useState(0)
  const images = [
    "/images/display/id.png",
    "/images/display/playing.png",
    "/images/display/episode.png",
    "/images/display/sleeper.png",
    "/images/display/schedule.png",
    "/images/display/reminder.png",
  ]

  const imgTags = []

  for (const i in images) {
    const image = images[i]

    imgTags.push(
      <img
        alt=""
        style={{
          opacity: +i == currentImg ? 1 : 0,
          transition: "opacity 2s",
        }}
        src={image}
        key={image}
      />,
    )
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImg((currentImg) =>
        currentImg < imgTags.length - 1 ? currentImg + 1 : 0,
      )
    }, 4000)

    return () => clearInterval(interval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <div id="device">{imgTags}</div>
}
