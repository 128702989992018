import { useState } from "react"
import Slideshow from "../components/slideshow"
import { Link } from "@remix-run/react"

export default function Index() {
  const [appstoreLinksVisible, setAppstoreLinksVisible] = useState(false)

  function toggleAppstoreLinks(e: React.MouseEvent<Element, MouseEvent>) {
    e.preventDefault()
    setAppstoreLinksVisible(!appstoreLinksVisible)
  }

  function toggleShareLinks(e: React.MouseEvent<Element, MouseEvent>) {
    e.preventDefault()
    const links = document.getElementById("social")

    if (links == null) {
      return
    }

    if (links.style.display === "none") {
      links.style.display = "block"
    } else {
      links.style.display = "none"
    }
  }

  return (
    <>
      <div className="container">
        <h1 id="leadin">
          24-Hour <br />
          Internet Radio <br />
          Committed <br />
          To <em>the</em> Historic <br />
          Christian Faith
        </h1>
        <p id="featuring">
          <em>Preaching, teaching, Scripture, music, & audiobooks</em>
        </p>
      </div>

      <Slideshow />

      <section id="actions" className="cf">
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <Link onClick={toggleAppstoreLinks} to="#" id="get_app">
          GET THE APP
        </Link>
        <nav
          id="stores"
          className="cf"
          style={{
            height: appstoreLinksVisible ? "180px" : "0",
            transition: "height .25s",
          }}
        >
          <Link
            id="apple"
            to="https://bit.ly/RefNetApp"
            target="_blank"
            rel="noreferrer"
            data-testid="home-apple-app-link"
          >
            Apple App Store
          </Link>
          <Link
            id="google"
            to="https://play.google.com/store/apps/details?id=com.ligonier.refnet"
            target="_blank"
            rel="noreferrer"
            data-testid="home-google-app-link"
          >
            Google Play
          </Link>
          <Link
            id="amazon"
            to="https://www.amazon.com/Ligonier-Ministries-RefNet-Christian-Radio/dp/B00BPJJGF0"
            target="_blank"
            rel="noreferrer"
            data-testid="home-amazon-app-link"
          >
            Amazon Appstore
          </Link>
        </nav>
        <Link
          id="listen"
          to="https://listen.refnet.fm/#start"
          target="_blank"
          rel="noreferrer"
          data-testid="home-listen-online-link"
        >
          LISTEN ONLINE
        </Link>
      </section>

      <footer id="footer" className="container">
        <div id="bottom">
          <Link to="/about" data-testid="footer-learn-more-link">
            LEARN MORE
          </Link>
          &nbsp;•&nbsp;
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link to="#" onClick={toggleShareLinks} id="share_btn">
            SHARE
          </Link>
        </div>
        <div className="col_12">
          <ul id="social" className="cf" style={{ display: "none" }}>
            <li>
              <iframe
                title="Follow us on facebook"
                src="//www.facebook.com/plugins/like.php?to=http%3A%2F%2Frefnet.fm&amp;send=false&amp;layout=button_count&amp;width=120&amp;show_faces=false&amp;font=lucida+grande&amp;colorscheme=light&amp;action=like&amp;height=21"
                scrolling="no"
                frameBorder="0"
              ></iframe>
            </li>

            <li>
              <Link
                to="https://twitter.com/share"
                className="twitter-share-button"
                data-url="https://refnet.fm"
                data-via="refnet"
                data-related="refnet"
                data-testid="footer-twitter-link"
              >
                twitter
              </Link>
            </li>
          </ul>

          <p>
            An Outreach of{" "}
            <Link
              to="https://www.ligonier.org/"
              target="_blank"
              rel="noreferrer"
              data-testid="footer-ligonier-link"
            >
              Ligonier
            </Link>{" "}
            <span className="copyright">&copy; {new Date().getFullYear()}</span>
          </p>
        </div>
        <div className="clear">&nbsp;</div>
      </footer>
    </>
  )
}
